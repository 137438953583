import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { PatchDataWrapper } from '@diamond/shared/data-access';
import { BackofficeOrderDetail } from '@diamond/shared/types';
import { Button, Modal, showToast, Text } from '@diamond/shared/ui';
import {
  adminDateFormatter,
  paymentMethodFormatterTruncate,
} from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { formatDeliveryDate } from '@diamond/sol-admin/common';
import { useCustomerTypes } from '@diamond/sol-admin-context';
import { EditOutlined, InfoOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const API_URL = 'backoffice/order';

interface ActivitiesDetailInfoProps {
  data: BackofficeOrderDetail;
  cancelReasonData: { label: string; value: string }[];
  refetch: () => void;
}

export function ActivitiesDetailnfo({
  data,
  cancelReasonData,
  refetch,
}: ActivitiesDetailInfoProps) {
  const params = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenLihatSemua,
    onOpen: onOpenLihatSemua,
    onClose: onCloseLihatSemua,
  } = useDisclosure();
  const session = useAuthStore();
  const toast = useToast();
  const customerTypesQuery = useCustomerTypes();
  const statusData = data.order.status;
  const isDisable =
    statusData !== 'submitted' && statusData !== 'waiting_for_payment';
  const activitiesId = params['activitiesId'] as string;

  const [cancelReasonItem, setCancelReasonItem] = useState<
    { label: string; value: string } | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, watch } = useFormContext();
  const { patchData } = PatchDataWrapper(
    API_URL,
    session.access_token,
    'admin'
  );
  const isPaymentProof = data.order.attachments.payment_proofs.length !== 0;

  const handleEdit = (value: string) => {
    setIsLoading(true);
    patchData({ cancel_reason_id: value }, `${data.order.id}`)
      .then(() => {
        showToast(toast, 'success', 'Berhasil mengubah alasan pembatalan');
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
        refetch();
      })
      .catch((error) => {
        showToast(toast, 'error', error.message);
      });
  };

  const handleSubmitNote = (value: string) => {
    patchData({ note: value }, `${data.order.id}`)
      .then(() => {
        showToast(toast, 'success', 'Berhasil mengubah catatan');
        onClose();
        refetch();
      })
      .catch((error) => {
        showToast(toast, 'error', error.message);
      });
  };

  const cancelReason = [
    {
      label: ' ',
      value: '',
    },
    ...cancelReasonData,
  ];

  useEffect(() => {
    if (data.order.cancel_reason_id) {
      const reason = cancelReason.find(
        (item) => item.value === data.order.cancel_reason_id
      );
      setCancelReasonItem(reason);
    }
  }, [patchData]);

  function openMultipleLinks(links: string[]) {
    // links.forEach((url) => window.open(url));
    links.forEach((url) => {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.click();
    });
  }

  return (
    <Flex color="white" my="4" gap={2}>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={2} flex="1">
        <HStack
          direction="column"
          justifyContent="space-between"
          verticalAlign="top"
        >
          <Box flex="1">
            <TableContainer>
              <Table variant="simple" size="sm" w="sm">
                <Tbody>
                  <Tr>
                    <Td>Nomor PO SOL</Td>
                    <Td fontWeight="bold">
                      {data.order.transaction_id || '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Nomor Sub PO SOL</Td>
                    <Td fontWeight="bold">{data.order.sub_po_number || '-'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tanggal PO</Td>
                    <Td fontWeight="bold">
                      {adminDateFormatter(data.order.created_at) || '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Nomor SO</Td>
                    <Td fontWeight="bold">{data.order.so_number || '-'}</Td>
                  </Tr>
                  <Tr>
                    <Td>Pembayaran</Td>
                    <Td fontWeight="bold">
                      {paymentMethodFormatterTruncate(
                        String(data?.order?.payment_method)
                      )}{' '}
                      {data?.order?.payment_method === 'term_of_payment'
                        ? `${data?.order?.user.term_of_payment_days} Hari`
                        : ''}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box flex="1">
            <TableContainer>
              <Table variant="simple" size="sm" w="sm">
                <Tbody>
                  <Tr>
                    <Td>Nomor Ship To</Td>
                    <Td fontWeight="bold">
                      {data.order.ship_to ? data.order.ship_to['code'] : '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Nama Shipto</Td>
                    <Td fontWeight="bold">{data.order.ship_to['name']}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipe Customer</Td>
                    <Td fontWeight="bold">
                      {customerTypesQuery.maps[
                        data.order.ship_to[
                          'cg_1_business_segmentation'
                        ] as string
                      ] || '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Alamat Pengiriman</Td>
                    <Td fontWeight="bold">
                      {data.order.ship_to ? data.order.ship_to['address'] : '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Nama Pemesan</Td>
                    <Td fontWeight="bold">
                      {data.order.ship_to_contact?.['name'] || '-'}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>No HP Pemesan</Td>
                    <Td fontWeight="bold">
                      {data.order.ship_to_contact?.['telp'] || '-'}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </HStack>
      </Box>
      <Box
        flexBasis="440px"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={4}
      >
        <Grid templateColumns="repeat(3, 1fr)" gap={2}>
          <GridItem>
            <Text textColor="black" fontSize="sm">
              Tanggal Pengiriman
            </Text>
          </GridItem>
          <GridItem justifySelf="flex-end" colSpan={2}>
            <Text px="4" textColor="black" fontSize="sm" fontWeight="semibold">
              {formatDeliveryDate(
                data.order.delivery_option,
                data.order.delivery_date
              )}
            </Text>
          </GridItem>
          <GridItem>
            <Text textColor="black" fontSize="sm">
              Alasan Pembatalan
            </Text>
          </GridItem>
          <GridItem justifySelf="flex-end" colSpan={2}>
            {isDisable && data.order.cancel_reason_id ? (
              <Text
                px="4"
                textColor="black"
                fontSize="sm"
                fontWeight="semibold"
              >
                {cancelReasonItem?.label}
              </Text>
            ) : (
              <Menu>
                <MenuButton
                  as={Button}
                  px="4"
                  color="blue"
                  variant="link"
                  size="xs"
                  rightIcon={<EditOutlined fontSize="small" />}
                  hidden={isDisable}
                  isLoading={isLoading}
                >
                  {cancelReasonItem ? cancelReasonItem.label : 'Ubah'}
                </MenuButton>
                <Portal>
                  <MenuList zIndex={20}>
                    {cancelReason.map((value, index) => {
                      if (value.value !== '16') {
                        return (
                          <MenuItem
                            height="2rem"
                            key={index}
                            onClick={() => handleEdit(value.value)}
                          >
                            {value.label}
                          </MenuItem>
                        );
                      } else {
                        return '';
                      }
                    })}
                  </MenuList>
                </Portal>
              </Menu>
            )}
          </GridItem>
          <GridItem colSpan={2}>
            <Text textColor="black" fontSize="sm">
              Catatan SAP
            </Text>
          </GridItem>
          <GridItem justifySelf="flex-end">
            <Text px="4" textColor="black" fontSize="sm" fontWeight="semibold">
              {data.order.sap_note ? data.order.sap_note : '-'}
            </Text>
          </GridItem>
          <GridItem colSpan={isDisable ? 3 : 2}>
            <Flex>
              <Text textColor="black" fontSize="sm">
                Catatan Pengiriman
              </Text>
              {data.order.delivery_note && (
                <GridItem colStart={isDisable ? 3 : 2}>
                  <Text
                    ml={isDisable ? '7rem' : '3rem'}
                    textColor="black"
                    fontWeight="semibold"
                    fontSize="sm"
                  >
                    {data.order.delivery_note.length > 100
                      ? data.order.delivery_note.slice(0, 100) + '. . .'
                      : data.order.delivery_note}
                    {isDisable && data.order.delivery_note.length > 100 && (
                      <Button
                        color="blue"
                        variant="link"
                        onClick={onOpenLihatSemua}
                      >
                        <Text fontSize="s" px="2">
                          Lihat Semua
                        </Text>
                      </Button>
                    )}
                  </Text>
                </GridItem>
              )}
            </Flex>
          </GridItem>
          {!isDisable && (
            <GridItem justifySelf="flex-end" overflowY="auto" height="auto">
              <Button
                px="4"
                color="blue"
                variant="link"
                size="xs"
                rightIcon={<EditOutlined fontSize="small" />}
                onClick={onOpen}
                hidden={isDisable}
              >
                Ubah
              </Button>
            </GridItem>
          )}
          <GridItem>
            <Text textColor="black" fontSize="sm">
              Bukti Transfer
            </Text>
          </GridItem>
          <GridItem justifySelf="flex-end" colSpan={2}>
            <ChakraLink
              href={
                isPaymentProof
                  ? `/activities/${activitiesId}/payment-proof`
                  : undefined
              }
              isExternal
              textColor="blue"
              fontWeight="bold"
              alignItems="center"
              cursor={isPaymentProof ? 'pointer' : 'not-allowed'}
              opacity={isPaymentProof ? 1 : 0.4}
              _hover={{ textDecoration: 'underline' }}
              pr={6}
            >
              Lihat Detail
            </ChakraLink>
          </GridItem>
          <GridItem>
            <Text textColor="black" fontSize="sm">
              Lampiran PO
            </Text>
          </GridItem>
          <GridItem justifySelf="flex-end" colSpan={2}>
            <Button
              px="4"
              color="blue"
              variant="link"
              size="xs"
              onClick={() => openMultipleLinks(data.order.attachments.po_docs)}
              isDisabled={data.order.attachments.po_docs.length === 0}
            >
              Download
              <Tooltip
                borderRadius="lg"
                p="4"
                hasArrow
                label={
                  <VStack w="fit-content">
                    <Box>Mohon aktifkan pop-up windows pada browser Anda:</Box>
                    <Box>
                      <Image
                        src="/assets/images/allow-popup-chrome.png"
                        alt="SUKANDA ONELINK"
                      />
                    </Box>
                  </VStack>
                }
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </Button>
          </GridItem>
        </Grid>
      </Box>
      <Modal
        size="lg"
        isCentered
        isOpen={isOpenLihatSemua}
        onClose={onCloseLihatSemua}
        name="delivery_note"
        title="Catatan"
        idButton="modal-note"
        hideAction
      >
        <Text>{data.order.delivery_note}</Text>
      </Modal>
      <Modal
        size="lg"
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        name="delivery_note"
        title="Catatan"
        onSubmit={() => handleSubmitNote(watch('order.delivery_note'))}
        labelSubmit="Submit"
        idButton="modal-note"
      >
        <Textarea
          {...register('order.delivery_note')}
          defaultValue={data.order.delivery_note as string}
        />
      </Modal>
    </Flex>
  );
}
