import {
  Box,
  Center,
  Flex,
  GridItem,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Db } from '@diamond/shared/schema';
import { IconButton } from '@diamond/shared/ui';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { AdminLayout } from '@diamond/sol-admin/common';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
// import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type AdminMenuProps = {
  id: number;
  href: string;
  label: string;
  icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
    muiName: string;
  };
  requiredRoles: Db['users']['role'][];
};

const adminMenus: Array<AdminMenuProps> = [
  {
    id: 1,
    href: '/account',
    label: 'Account & Contact',
    icon: ContactsOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 2,
    href: '/activities',
    label: 'Activities',
    icon: AutoGraphOutlinedIcon,
    requiredRoles: ['super_admin', 'cs_katalog', 'cs_cabang'],
  },
  {
    id: 3,
    href: '/user',
    label: 'Users',
    icon: PeopleAltOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  // TODO; need to be hide
  // {
  //   id: 4,
  //   href: '#',
  //   label: 'Items',
  //   icon: InsertPhotoOutlinedIcon,
  // },
  {
    id: 5,
    href: '/banners',
    label: 'Banner Management',
    icon: CategoryOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 6,
    href: '/minimum-order',
    label: 'Minimum Order Management',
    icon: AddShoppingCartOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 7,
    href: '/keywords',
    label: 'Keyword Management',
    icon: ManageSearchOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
  {
    id: 8,
    href: '/highlight',
    label: 'Highlight Management',
    icon: ExtensionOutlinedIcon,
    requiredRoles: ['super_admin'],
  },
];

export function HomePage() {
  const { userRole } = useAuthStore();
  const currentRole = userRole as Db['users']['role'];

  const filteredMenu = useMemo(() => {
    return adminMenus.filter((menus) =>
      menus.requiredRoles.includes(currentRole)
    );
  }, [adminMenus]);

  return (
    <AdminLayout>
      <Box mb={20}>
        <Flex flex={1} display={{ base: 'flex', md: 'flex' }}>
          <Box overflow="hidden" flex={1} rounded="md" m="unset">
            <Image
              src="/assets/images/admin-banner-home.png"
              alt="Admin Homepage Banner"
              objectFit="cover"
              height={{ base: 160, md: 300 }}
            />
          </Box>
        </Flex>

        <Box mt={8}>
          <SimpleGrid
            gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
            spacing={6}
          >
            {filteredMenu.map((menu) => (
              <GridItem
                key={menu.id}
                w="100%"
                h="96px"
                borderRadius="md"
                border="1px"
                _hover={{ bg: 'gray.50' }}
              >
                <Link as={RouterLink} to={menu.href}>
                  <HStack
                    spacing={4}
                    py={2.5}
                    px={2.5}
                    bg="transparent"
                    borderRadius="md"
                    cursor="pointer"
                  >
                    <Center
                      bg="white"
                      borderRadius="md"
                      color="blue"
                      border="1px"
                      w="72px"
                      h="72px"
                    >
                      <IconButton
                        name={menu.label}
                        icon={<menu.icon fontSize="large" />}
                        aria-label={menu.label}
                        variant="unstyled"
                        width={20}
                      />
                    </Center>
                    <Box>
                      <Text as="a" fontSize="lg" fontWeight="semibold">
                        {menu.label}
                      </Text>
                    </Box>
                  </HStack>
                </Link>
              </GridItem>
            ))}
          </SimpleGrid>
        </Box>
      </Box>
    </AdminLayout>
  );
}

export default HomePage;
