/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  AddOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  InsertDriveFileOutlined,
} from '@mui/icons-material';
import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DropEvent,
  DropzoneState,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import { FieldErrorsImpl, useFormContext } from 'react-hook-form';

import { showToast } from '../toast/toast';

export interface ChakraDropzoneMultipleProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  header?: string;
  disabled?: boolean;
  required?: boolean;
  maxFiles?: number;
  maxSize?: number;
  errors?: Partial<FieldErrorsImpl<{ [x: string]: string }>>;
  accept?: any;
  isCentered?: boolean;
  onRemoveFile: (idx: number) => void;
}

export const DropzoneMultiple: FC<ChakraDropzoneMultipleProps> = ({
  name,
  header,
  disabled,
  errors,
  accept,
  maxSize = 0,
  isCentered = false,
  onRemoveFile,
}) => {
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [filesSave, setFilesSave] = useState<File[]>([]);
  const { register, unregister, setValue, watch } = useFormContext();
  const errorForm = errors ? errors[name]?.message : '';
  const [errorMessage, setErrorMessage] = useState<string>(errorForm as string);
  const files: File[] = watch(name);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [filePrev, setFilePrev] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');

  const onDrop = useCallback(
    (droppedFiles: any) => {
      if (droppedFiles.length > 0) {
        setValue(name, droppedFiles, { shouldValidate: true });
      }
    },
    [setValue, name]
  );

  function maxSizeValidator(file: File) {
    if (file.size > maxSize * 1000000) {
      return {
        code: 'file-too-large',
        message: `File is larger than ${maxSize} MB`,
      };
    }

    return null;
  }

  function onDropRejected(fileRejections: FileRejection[], event: DropEvent) {
    setErrorMessage(fileRejections[0].errors[0].message ?? 'Invalid file type');
  }

  function onDropAccepted() {
    setErrorMessage('');
  }

  const fileDropzone = useDropzone({
    onDrop,
    onDropRejected,
    onDropAccepted,
    validator: maxSizeValidator,
    accept: accept,
    disabled: disabled,
    noClick: true,
    noKeyboard: true,
  });

  const { getRootProps } = fileDropzone;

  const imageDropzone = useDropzone({
    onDrop,
    onDropRejected,
    onDropAccepted,
    validator: maxSizeValidator,
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg'],
    },
    disabled: disabled,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  useMemo(() => {
    const idToast = 'file-bukti-bayar';
    if (files && files.length > 0) {
      const itemExists = filesSave.some(
        (item) => item.name === files[0].name && item.size === files[0].size
      );
      if (!itemExists) {
        setFilesSave([...filesSave, files[0]]);
      } else {
        if (!toast.isActive(idToast)) {
          showToast(toast, 'error', 'File yang anda pilih sudah ada', idToast);
        }
      }
    }
  }, [files]);

  const handlePreview = (file: File) => {
    const tmpFilePath = URL.createObjectURL(file);
    setFilePrev(tmpFilePath);
    setFileType(file.type);
    setIsViewerOpen(true);
  };

  const removeFile = (index: number) => {
    const newItems = [...filesSave];
    newItems.splice(index, 1);
    setFilesSave(newItems);
    onRemoveFile(index);
  };

  const acceptedFileItems = filesSave?.map((file: File, index: number) => {
    const fileName =
      file?.name.length > 18 ? `${file?.name.slice(0, 18)}...` : file?.name;

    return (
      <Stack key={index} textAlign="center">
        <Stack direction="row" justifyContent="center">
          <Card
            width="fit-content"
            boxShadow="none"
            size={{ base: 'sm', md: 'md' }}
          >
            <CardHeader padding={0} textAlign="end">
              <IconButton
                variant="ghost"
                aria-label="delete-file"
                border="1px"
                borderRadius="full"
                color="gray.75"
                size="xs"
                icon={<CloseOutlined fontSize="small" />}
                onClick={() => removeFile(index)}
              />
            </CardHeader>
            <CardBody paddingTop={0} _hover={{ cursor: 'pointer' }}>
              <Icon
                as={InsertDriveFileOutlined}
                height={{ base: 10, md: 20 }}
                width={{ base: 10, md: 20 }}
                marginRight={1}
                padding={2}
                bgColor="gray.33"
                color="blue"
                borderRadius="md"
                onClick={() => handlePreview(file)}
              />
            </CardBody>
          </Card>
        </Stack>
        <Tooltip label={file?.name} aria-label={file?.name}>
          <Text fontSize={{ base: '12px', md: '16px' }}>{fileName}</Text>
        </Tooltip>
      </Stack>
    );
  });

  return (
    <Stack>
      <Container
        as={Center}
        {...getRootProps()}
        maxW="full"
        bgColor={`${files?.length > 0 ? 'white' : 'gray.customGray'}`}
        border="dashed"
        borderRadius="md"
        borderColor="gray.75"
        centerContent
        overflowX="auto"
        h={`${isMobile ? 56 : 60}`}
      >
        {files?.length > 0 ? (
          <Stack alignSelf="flex-start" direction="row" spacing={4}>
            {acceptedFileItems}
            <Stack textAlign="center">
              {isMobile ? (
                <AddMoreFileMobile
                  dropzoneFileState={fileDropzone}
                  dropzoneImageState={imageDropzone}
                />
              ) : (
                <AddMoreFileDesktop dropzoneState={fileDropzone} />
              )}
              <Text
                textColor="blue"
                fontWeight="semibold"
                fontSize={{ base: '12px', md: '16px' }}
              >
                Tambah File
              </Text>
            </Stack>
          </Stack>
        ) : isMobile ? (
          <DropzoneUploadMobile
            dropzoneFileState={fileDropzone}
            dropzoneImageState={imageDropzone}
          />
        ) : (
          <DropzoneUploadDesktop dropzoneState={fileDropzone} />
        )}
      </Container>
      <Text textColor="red">{errorMessage}</Text>
      <Modal
        isOpen={isViewerOpen}
        onClose={() => {
          setIsViewerOpen(false);
          setFilePrev('');
          setFileType('');
        }}
        closeOnEsc
        size="3xl"
        isCentered={isCentered}
      >
        <ModalOverlay />
        <ModalContent>
          {header && <ModalHeader>{header}</ModalHeader>}
          <ModalBody margin="auto" overflowY="auto" p="0">
            {fileType === 'image/png' ||
            fileType === 'image/jpg' ||
            fileType === 'image/jpeg' ? (
              <img
                src={filePrev}
                alt="preview file"
                width="100%"
                height="100%"
              />
            ) : (
              <object
                data={filePrev}
                title="preview file"
                width={isMobile ? '100%' : '600px'}
                height="700px"
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

type DropzoneUploadDesktopProps = {
  dropzoneState: DropzoneState;
};

function DropzoneUploadDesktop({ dropzoneState }: DropzoneUploadDesktopProps) {
  const { getInputProps, open } = dropzoneState;
  return (
    <>
      <input {...getInputProps()} />
      <Icon
        as={CloudUploadOutlined}
        fontSize={{ base: '4xl', lg: '7xl' }}
        color="blue"
      />
      <Text fontSize={{ base: 'sm', lg: 'md' }} textAlign="center">
        Tarik dan lepas file disini
      </Text>
      <Text fontSize="sm" textAlign="center" mt="2">
        atau
      </Text>
      <Button
        variant="ghost"
        textColor="blue"
        _hover={{ bgColor: 'transparent' }}
        onClick={open}
        pb={2}
      >
        Cari file
      </Button>
    </>
  );
}

type DropzoneUploadMobileProps = {
  dropzoneFileState: DropzoneState;
  dropzoneImageState: DropzoneState;
};

function DropzoneUploadMobile({
  dropzoneFileState,
  dropzoneImageState,
}: DropzoneUploadMobileProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  return (
    <>
      <input {...dropzoneFileState.getInputProps()} />
      <input {...dropzoneImageState.getInputProps()} />
      <Icon
        as={CloudUploadOutlined}
        fontSize={{ base: '4xl', lg: '7xl' }}
        color="blue"
      />
      <>
        <Button
          variant="ghost"
          textColor="blue"
          pb={2}
          _hover={{ bgColor: 'transparent' }}
          ref={btnRef}
          colorScheme="teal"
          onClick={onOpen}
        >
          Unggah
        </Button>
        <DrawerMobile
          dropzoneFileState={dropzoneFileState}
          dropzoneImageState={dropzoneImageState}
          isOpen={isOpen}
          onClose={onClose}
          btnRef={btnRef}
        />
      </>
    </>
  );
}

function AddMoreFileDesktop({ dropzoneState }: DropzoneUploadDesktopProps) {
  return (
    <>
      <input {...dropzoneState.getInputProps()} />
      <Stack direction="row" justifyContent="center">
        <Card
          width="fit-content"
          boxShadow="none"
          size={{ base: 'sm', md: 'md' }}
        >
          <CardBody marginTop={{ base: 2, md: 0 }}>
            <IconButton
              aria-label="add-item"
              height={{ base: 10, md: 20 }}
              width={{ base: 10, md: 20 }}
              color="white"
              bgColor="rgba(0, 0, 163, 0.5);"
              border="3px"
              borderStyle="dashed"
              borderColor="blue"
              borderRadius="md"
              as={AddOutlined}
              onClick={dropzoneState.open}
            />
          </CardBody>
        </Card>
      </Stack>
    </>
  );
}

function AddMoreFileMobile({
  dropzoneFileState,
  dropzoneImageState,
}: DropzoneUploadMobileProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <input {...dropzoneFileState.getInputProps()} />
      <input {...dropzoneImageState.getInputProps()} />

      <Stack direction="row" justifyContent="center">
        <Card
          width="fit-content"
          boxShadow="none"
          size={{ base: 'sm', md: 'md' }}
        >
          <CardBody marginTop={{ base: 2, md: 0 }}>
            <>
              <IconButton
                aria-label="add-item"
                height={{ base: 10, md: 20 }}
                width={{ base: 10, md: 20 }}
                color="white"
                bgColor="rgba(0, 0, 163, 0.5);"
                border="3px"
                borderStyle="dashed"
                borderColor="blue"
                borderRadius="md"
                as={AddOutlined}
                onClick={onOpen}
              />
              <DrawerMobile
                dropzoneFileState={dropzoneFileState}
                dropzoneImageState={dropzoneImageState}
                isOpen={isOpen}
                onClose={onClose}
                btnRef={btnRef}
              />
            </>
          </CardBody>
        </Card>
      </Stack>
    </>
  );
}

type DrawerMobileProps = DropzoneUploadMobileProps & {
  isOpen: boolean;
  onClose: () => void;
  btnRef: RefObject<HTMLButtonElement>;
};

function DrawerMobile({
  dropzoneFileState,
  dropzoneImageState,
  isOpen,
  onClose,
  btnRef,
}: DrawerMobileProps) {
  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader textAlign="center">Unggah File</DrawerHeader>

        <DrawerBody pt={2} pb={5}>
          <VStack>
            <Button
              w="full"
              variant="outline"
              textColor="blue"
              fontWeight="normal"
              _hover={{ bgColor: 'transparent' }}
              onClick={dropzoneImageState.open}
            >
              Pilih dari galeri
            </Button>
            <Button
              w="full"
              variant="outline"
              textColor="blue"
              fontWeight="normal"
              _hover={{ bgColor: 'transparent' }}
              onClick={dropzoneFileState.open}
            >
              Pilih dari file manager
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default DropzoneMultiple;
