import {
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { format as formatDate } from 'date-fns';
import { useState } from 'react';
import {
  DateRange,
  DayPicker,
  DayPickerRangeProps,
  SelectRangeEventHandler,
} from 'react-day-picker';
import { useFormContext } from 'react-hook-form';

import InputDate from '../input-date';

export type RangeDatePickerProps = DayPickerRangeProps & {
  name: string;
  format?: string;
  mode?: 'range';
};
export function RangeDatePicker({
  selected,
  name,
  mode = 'range',
  format = 'dd/MM/yyyy',
}: RangeDatePickerProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selectedRange, setSelectedRange] = useState(selected);

  const handleRangeSelect: SelectRangeEventHandler = (
    range: DateRange | undefined
  ) => {
    setSelectedRange(range);
    if (range?.from && range?.to) {
      setValue(`${name}-from`, formatDate(range.from, format));
      setValue(`${name}-to`, formatDate(range.to, format));
      onClose();
    } else {
      onOpen();
    }
  };

  return (
    <Popover
      placement="bottom-start"
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
    >
      <PopoverTrigger>
        <Stack direction={['column', 'row']} justifyContent="end">
          <InputDate
            name={`${name}-from`}
            register={register}
            errors={errors}
          />
          <Center as="b" alignContent={'center'}>
            Sampai
          </Center>
          <InputDate name={`${name}-to`} register={register} errors={errors} />
        </Stack>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <DayPicker
            mode={mode}
            selected={selectedRange}
            onSelect={handleRangeSelect}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
