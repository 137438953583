import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Table: ComponentStyleConfig = {
  baseStyle: {
    th: {
      background: '#E1E1F1',
      textTransform: 'capitalize',
    },
    td: {
      textColor: 'blue',
      borderBottom: 'none',
    },
  },
  variants: {
    simple: {
      thead: {
        th: {
          background: 'white',
          borderBottom: 'none',
          textColor: 'black',
        },
      },
      tbody: {
        td: {
          textColor: 'black',
          borderBottom: 'none',
          background: 'white',
        },
      },
    },
    striped: {
      thead: {
        th: {
          borderBottom: 'none',
          textColor: 'blue',
        },
      },
      tbody: {
        tr: {
          '&:nth-of-type(odd)': {
            td: {
              background: '#FAFAFA',
            },
          },
        },
        td: {
          background: 'gray.customGray',
          borderBottom: 'none',
        },
      },
    },
  },
};
