import {
  Button,
  Center,
  Container,
  Icon,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  ADDITIONAL_CLIENT_HEADERS,
  DEFAULT_API_HEADERS,
} from '@diamond/shared/environments';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Papa from 'papaparse';
import { FC, useCallback, useEffect, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { FieldErrorsImpl, useFormContext } from 'react-hook-form';
import readXlsxFile from 'read-excel-file';

import Modal from '../modal/modal';

export interface ChakraDropzoneProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  disabled?: boolean;
  maxFiles?: number;
  maxSize?: number;
  urlDownload?: string;
  fileName?: string;
  errors?: Partial<FieldErrorsImpl<{ [x: string]: string }>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accept?: any;
  isCentered?: boolean;
  setIsFileAttached?: (val: boolean) => void;
  customErrorMessage?: string;
}

export const Dropzone: FC<ChakraDropzoneProps> = ({
  name,
  disabled,
  maxFiles = 1,
  errors,
  urlDownload,
  fileName,
  accept,
  maxSize = 2,
  isCentered = false,
  setIsFileAttached,
  customErrorMessage,
}) => {
  const [fileUrl, setFileUrl] = useState<string | undefined>(urlDownload);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const initialButtonLabel =
    fileName && fileName.match(/\.(jpg|jpeg|png)$/i) ? 'Preview' : 'Download';
  const [downloadOrPreviewState, setDownloadOrPreviewState] = useState<
    'Download' | 'Preview'
  >(initialButtonLabel);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [viewerFileUrl, setViewerFileUrl] = useState('');
  const [viewerFileType, setViewerFileType] = useState<
    'image' | 'csv' | 'xls'
  >();
  const [isError, setIsError] = useState<boolean>(false);

  // State for parsed CSV data
  const [_, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [csvValues, setCsvValues] = useState([]);

  const { register, unregister, setValue, watch, resetField } =
    useFormContext();
  const errorForm = errors ? errors[name]?.message : '';
  const [errorMessage, setErrorMessage] = useState<string>(errorForm as string);
  const files: File[] = watch(name);

  const isImageFile = (file: File) => {
    return (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    );
  };

  const isCsvFile = (file: File) => {
    return file.type === 'text/csv' || file.type === 'text/x-csv';
  };

  const isExcelFile = (file: File) => {
    return (
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  };

  const onDrop = useCallback(
    (droppedFiles: File[]) => {
      if (droppedFiles.length > 0) {
        handleDeleteFile();
        setValue(name, droppedFiles, { shouldValidate: true });
        setIsFileAttached && setIsFileAttached(true);
        setDownloadOrPreviewState(
          isImageFile(droppedFiles[0]) ||
            isCsvFile(droppedFiles[0]) ||
            isExcelFile(droppedFiles[0])
            ? 'Preview'
            : 'Download'
        );
      }
    },
    [setValue, name]
  );

  const maxSizeValidator = (file: File) => {
    if (file.size > maxSize * 1000000) {
      return {
        code: 'file-too-large',
        message: `File is larger than ${maxSize} MB`,
      };
    }

    return null;
  };

  // TODO replace any types with the actual types
  const parseCsvFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: any) {
        const rowsArray: any = [];
        const valuesArray: any = [];

        if (results.data.length === 0) {
          setErrorMessage('Pastikan terdapat data didalam berkas CSV!');
          setIsViewerOpen(false);
          return setIsError(true);
        }

        // Iterating data to get column name and their values
        results.data.map((d: any) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        setParsedData(results.data); // Parsed Data Response in array format
        setTableRows(rowsArray[0]); // Filtered Column Names
        setCsvValues(valuesArray); // Filtered Values
      },
    });
  };

  const parseExcelFile = async (file: File) => {
    return readXlsxFile(file).then((rows: any) => {
      const rowsArray: any = [];
      const valuesArray: any = [];

      if (rows.length === 0) {
        setErrorMessage('Pastikan terdapat data didalam berkas Excel!');
        setIsViewerOpen(false);
        return setIsError(true);
      }

      // Iterating data to get column name and their values
      rows.map((d: any) => {
        rowsArray.push(Object.keys(d));
        valuesArray.push(Object.values(d));
      });

      valuesArray.shift(); // Remove first element
      setParsedData(rows.data); // Parsed Data Response in array format
      setTableRows(rows[0]); // Filtered Column Names
      setCsvValues(valuesArray); // Filtered Values
      openFilePreview(file.webkitRelativePath, 'xls');
    });
  };

  const onDropRejected = (
    fileRejections: FileRejection[],
    _event: DropEvent
  ) => {
    if (
      fileRejections[0].errors[0].code === 'file-invalid-type' &&
      customErrorMessage
    ) {
      setErrorMessage(customErrorMessage);
    } else {
      setErrorMessage(
        fileRejections[0].errors[0].message ?? 'Invalid file type'
      );
    }
  };

  const onDropAccepted = () => {
    setErrorMessage('');
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    onDropAccepted,
    validator: maxSizeValidator,
    accept: accept,
    maxFiles: maxFiles,
    disabled: disabled,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    register(name);
    return () => unregister(name);
  }, [register, unregister, name]);

  const openFilePreview = useCallback(
    (fileUrl: string, filetype: 'image' | 'csv' | 'xls') => {
      setViewerFileType(filetype);
      setViewerFileUrl(fileUrl);
      setIsViewerOpen(true);
    },
    []
  );

  const handleDownloadOrPreview = async () => {
    const windowUrl = window.URL || window.webkitURL;

    if (fileUrl) {
      if (fileName && fileName.match(/\.(jpg|jpeg|png)$/i)) {
        setViewerFileUrl(fileUrl);
        setViewerFileType('image');
        setDownloadOrPreviewState('Preview');
        return openFilePreview(fileUrl, 'image');
      }
      fetch(fileUrl, { method: 'GET', mode: 'no-cors' })
        .then(async (_response) => {
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target = '_blank';
          link.click();
        })
        .catch();
    }

    if (files.length <= 0) return;

    const tmpFilePath = windowUrl.createObjectURL(files[0]);
    const link = document.createElement('a');

    switch (files[0].type) {
      case 'text/csv':
        parseCsvFile(files[0]);
        return openFilePreview(tmpFilePath, 'csv');
      case 'text/x-csv':
        parseCsvFile(files[0]);
        return openFilePreview(tmpFilePath, 'csv');
      case 'application/vnd.ms-excel':
        return parseExcelFile(files[0]);
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return parseExcelFile(files[0]);
      case 'image/jpeg':
        return openFilePreview(tmpFilePath, 'image');
      case 'image/jpg':
        return openFilePreview(tmpFilePath, 'image');
      case 'image/png':
        return openFilePreview(tmpFilePath, 'image');
      default:
        link.download = files[0].name;
        link.href = tmpFilePath;
        link.click();
        break;
    }
  };

  const handleDeleteFile = () => {
    setIsFileAttached && setIsFileAttached(false);
    setFileUrl('');
    resetField(name);
    setValue(name, null);
  };

  return (
    <>
      <Stack>
        <Container
          as={Center}
          {...getRootProps()}
          maxW="full"
          bgColor={`${files?.length > 0 ? 'white' : 'gray.customGray'}`}
          border="dashed"
          borderRadius="md"
          borderColor="gray.75"
          centerContent
          h={`${isMobile ? 44 : 60}`}
        >
          {fileUrl || files?.length > 0 ? (
            <>
              {!!files?.length && (
                <div className="grid gap-1 grid-cols-4 mt-2">
                  {files.map((file) => {
                    return (
                      <div key={file.name}>
                        <Text as="b">{file.name}</Text>
                      </div>
                    );
                  })}
                </div>
              )}

              <Button
                as={'a'}
                variant="ghost"
                textColor="blue"
                _hover={{ bgColor: 'transparent' }}
                onClick={() => handleDownloadOrPreview()}
                cursor="pointer"
              >
                {`${downloadOrPreviewState} File`}
              </Button>

              <Button
                variant="ghost"
                textColor="red"
                _hover={{ bgColor: 'transparent' }}
                onClick={handleDeleteFile}
                mt={4}
                size="sm"
              >
                Hapus File
              </Button>
            </>
          ) : (
            <>
              <input {...getInputProps()} />
              <Icon
                as={CloudUploadOutlinedIcon}
                fontSize={isMobile ? '4xl' : '7xl'}
                color="blue"
              />
              <Text fontSize={isMobile ? 'sm' : 'md'} textAlign="center">
                Tarik dan lepas file disini
              </Text>
              <Text fontSize="sm" textAlign="center" mt="2">
                atau
              </Text>
              <Button
                variant="ghost"
                textColor="blue"
                _hover={{ bgColor: 'transparent' }}
                onClick={open}
                pb={2}
              >
                Cari file
              </Button>
            </>
          )}
        </Container>
        <Text textColor="red">{errorMessage}</Text>
      </Stack>

      <ChakraModal
        isOpen={!isError && isViewerOpen}
        onClose={() => setIsViewerOpen(false)}
        size={viewerFileType === 'image' ? '2xl' : '5xl'}
        scrollBehavior={viewerFileType === 'image' ? 'outside' : 'inside'}
        isCentered={isCentered}
      >
        <ModalOverlay />
        <ModalContent zIndex={1800}>
          {viewerFileType !== 'image' && (
            <>
              <ModalHeader>Preview Data</ModalHeader>
              <ModalCloseButton />
            </>
          )}
          <ModalBody position="initial" padding={0}>
            {viewerFileType === 'image' ? (
              <img
                src={viewerFileUrl}
                alt="Preview image"
                width="auto"
                height="auto"
              />
            ) : (
              <TableContainer maxW="container.xl">
                <Table size="sm" variant="striped">
                  <Thead>
                    <Tr>
                      {tableRows.map((rows, index) => (
                        <Th key={index}>{rows}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {csvValues.map((value: any, index: number) => (
                      <Tr key={index}>
                        {value.map((val: any, i: number) => {
                          return <Td key={i}>{val}</Td>;
                        })}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
        </ModalContent>
      </ChakraModal>

      <Modal
        isCentered
        title="Perhatian!"
        name="state-changed"
        isOpen={isError}
        onClose={() => setIsError(false)}
        actionButtonPosition="center"
        labelCancel="Tutup"
        hideSubmit
        hideClose
        size="sm"
      >
        <div>{errorMessage}</div>
      </Modal>
    </>
  );
};

export default Dropzone;
