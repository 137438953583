import {
  Box,
  Flex,
  HStack,
  Image as ChakraImage,
  ImageProps,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import { BackofficeOrderPO } from '@diamond/shared/types';
import {
  Breadcrumbs,
  Button,
  Divider,
  PageLoader,
  Text,
  TextField,
} from '@diamond/shared/ui';
import {
  adminCurrencyFormatter,
  catalogMasking,
  paymentMethodFormatter,
  paymentMethodFormatterTruncate,
} from '@diamond/shared/utils';
import { adminDateFormatter } from '@diamond/shared/utils';
import { AdminLayout, formatDeliveryDate } from '@diamond/sol-admin/common';
import { useCustomerTypes, useGetPODetail } from '@diamond/sol-admin-context';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ActivitiesDetailPOTables } from './ActivitiesDetailPOTables';

type ActivitiesImagePaymentProps = ImageProps & {
  url: string;
};

type Divisions = BackofficeOrderPO['orders'][number]['division'];

type MappedTableData = Record<
  Divisions,
  BackofficeOrderPO['orders'][0] | undefined
>;

function ActivitiesImagePayment({
  url,
  ...props
}: ActivitiesImagePaymentProps) {
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
  }, [url]);

  const imagePosition =
    imageDimensions.height > imageDimensions.width
      ? {
          width: '25%',
          height: '25%',
        }
      : {
          width: '60%',
          height: '60%',
        };

  return <ChakraImage src={url} style={{ ...imagePosition }} {...props} />;
}

export function ActivitiesDetailPOPage() {
  const params = useParams();
  const activitiesId = params['orderMetaId'] as string;
  const methods = useForm();

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Activities',
      link: '/activities',
    },
    {
      title: 'Detail PO Checkout',
      link: `/activities/${activitiesId}`,
    },
  ];

  const { data, isPending: loadingAllData } = useGetPODetail(activitiesId);
  const customerTypesQuery = useCustomerTypes();

  const tableDatas = structuredClone(data?.orders.map((val) => val));

  const pdfUrls: string[] = [];
  const imageUrls: string[] = [];

  data?.attachments?.payment_proofs.forEach((url) => {
    const urlObj = new URL(url);

    if (urlObj.pathname.endsWith('.pdf') || urlObj.pathname.endsWith('.PDF')) {
      pdfUrls.push(url);
    } else if (
      urlObj.pathname.endsWith('.png') ||
      urlObj.pathname.endsWith('.PNG')
    ) {
      imageUrls.push(url);
    } else if (
      urlObj.pathname.endsWith('.jpg') ||
      urlObj.pathname.endsWith('.jpeg') ||
      urlObj.pathname.endsWith('.JPG') ||
      urlObj.pathname.endsWith('.JPEG')
    ) {
      imageUrls.push(url);
    }
  });

  const { diamond, equipment, food, trading }: MappedTableData = (() => {
    const initialValues = {
      diamond: undefined,
      equipment: undefined,
      fnb: undefined,
      food: undefined,
      trading: undefined,
    };

    if (!tableDatas) return initialValues;

    return tableDatas.reduce((acc, curr) => {
      if (curr.division === 'fnb' || curr.division === 'food') {
        acc['food'] = curr;
      } else {
        acc[curr.division] = curr;
      }
      return acc;
    }, {} as MappedTableData);
  })();

  if (loadingAllData) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  const openMultipleLinks = (links: string[]) => {
    links.forEach((url) => window.open(url));
  };

  return (
    <AdminLayout>
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <FormProvider {...methods}>
        <Box>
          <HStack
            direction="row"
            justifyContent="space-between"
            verticalAlign="center"
          >
            <Text fontSize="xl" fontWeight="bold">
              Detail PO Checkout
            </Text>
          </HStack>
          <Divider />
          <HStack my="3" spacing="4" direction="row">
            <Box>
              <Text fontSize="xl" color="blue" fontWeight="bold">
                {data?.transaction_id}
              </Text>
            </Box>
            <Spacer />
            <Box>
              <TextField
                width="25rem"
                name="total"
                placeholder={'Total Nilai Order'}
                register={methods.register}
                value={`Total PO Cart: ${adminCurrencyFormatter(data?.total)}`}
                isDisabled
                _disabled={{
                  color: 'black',
                }}
              />
            </Box>
          </HStack>
        </Box>
        <Divider />
        <Flex color="white" my="4">
          <Box
            w="100%"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={2}
          >
            <HStack
              direction="column"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <TableContainer>
                <Table variant="simple" size="sm">
                  <Tbody>
                    <Tr key="Tanggal-PO">
                      <Td width="4">Tanggal PO</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {adminDateFormatter(data?.created_at) || '-'}
                      </Td>
                    </Tr>
                    <Tr key="Nomor-Sub-PO">
                      <Td width="4">Nomor Sub PO</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.ship_to['distribution_channel'] ? (
                          <Box>
                            {data?.orders.map((order) => (
                              <Text fontSize="sm">
                                {order.sub_po_number} -{' '}
                                {catalogMasking(
                                  order.division,
                                  String(data?.ship_to['distribution_channel'])
                                )}
                              </Text>
                            ))}
                          </Box>
                        ) : null}
                      </Td>
                    </Tr>
                    <Tr key="Nomor-Ship-To">
                      <Td width="4">Nomor Ship To</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">{data?.ship_to['code'] || '-'}</Td>
                    </Tr>
                    <Tr key="pembayaran">
                      <Td width="4">Pembayaran</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {paymentMethodFormatterTruncate(
                          String(data?.payment_method)
                        )}{' '}
                        {data?.payment_method === 'term_of_payment'
                          ? `${data?.user?.term_of_payment_days} Hari`
                          : ''}
                      </Td>
                    </Tr>
                    <Tr key="nama-pemesan">
                      <Td width="4">Nama Pemesan</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.ship_to_contact?.['name'] || '-'}
                      </Td>
                    </Tr>
                    <Tr key="no-hp-pemesan">
                      <Td width="4">No HP Pemesan</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.ship_to_contact?.['telp'] || '-'}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <TableContainer width="50%">
                <Table variant="simple" size="sm">
                  <Tbody>
                    <Tr key="Nama-Akun">
                      <Td width="4">Nama Shipto</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.ship_to ? data.ship_to['name'] : '-'}
                      </Td>
                    </Tr>
                    <Tr key="Tipe-Customer">
                      <Td width="4">Tipe Customer</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.ship_to
                          ? customerTypesQuery.maps[
                              data.ship_to[
                                'cg_1_business_segmentation'
                              ] as string
                            ]
                          : '-'}
                      </Td>
                    </Tr>
                    <Tr key="No-Telp-Bill-To">
                      <Td width="4">No Telp Bill To</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.bill_to ? data.bill_to['telp_sol'] : '-'}
                      </Td>
                    </Tr>
                    <Tr key="Alamat">
                      <Td width="4">Alamat Pengiriman</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.ship_to ? data.ship_to['address'] : '-'}
                      </Td>
                    </Tr>
                    <Tr key="No-Telp-Ship-To">
                      <Td>No Telp Ship To</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td fontWeight="bold">
                        {data?.ship_to ? data.ship_to['telp_shipto'] : '-'}
                      </Td>
                    </Tr>
                    <Tr key="Catatan">
                      <Td width="4">Catatan Pengiriman</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td
                        fontWeight="bold"
                        maxW="300px"
                        wordBreak="break-word"
                        whiteSpace="pre-wrap"
                      >
                        {data?.delivery_note}
                      </Td>
                    </Tr>
                    <Tr key="tanggal-pengiriman">
                      <Td width="4">Tanggal Pengiriman</Td>
                      <Td width="1" pr="0">
                        :
                      </Td>
                      <Td
                        fontWeight="bold"
                        maxW="300px"
                        wordBreak="break-word"
                        whiteSpace="pre-wrap"
                      >
                        {formatDeliveryDate(
                          data?.delivery_option,
                          data?.delivery_date
                        )}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </HStack>
          </Box>
          <Spacer />
        </Flex>
        <Box width="full">
          <ActivitiesDetailPOTables
            register={methods.register}
            distributionChannel={String(data?.ship_to['distribution_channel'])}
            diamond={diamond}
            food={food}
            equipment={equipment}
            trading={trading}
          />
        </Box>
        {data?.payment_method === 'bank_transfer' ? (
          <>
            <Flex color="white" my="2">
              <Box
                w="100%"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={2}
              >
                <TableContainer>
                  <Table variant="simple" size="sm">
                    <Tbody>
                      <Tr key="Jenis Pembayaran">
                        <Td width="4">Jenis Pembayaran</Td>
                        <Td width="1" pr="0">
                          :
                        </Td>
                        <Td fontWeight="bold">
                          {paymentMethodFormatter(String(data?.payment_method))}
                        </Td>
                      </Tr>
                      {data?.payment_method === 'bank_transfer' ? (
                        <Tr key="Transfer-ke-Bank">
                          <Td width="4">Transfer ke Bank</Td>
                          <Td width="1" pr="0">
                            :
                          </Td>
                          <Td fontWeight="bold">
                            {data?.bank_info?.bank_name}
                          </Td>
                        </Tr>
                      ) : null}
                      <Tr key="Atas-Nama">
                        <Td width="4">Atas Nama</Td>
                        <Td width="1" pr="0">
                          :
                        </Td>
                        <Td fontWeight="bold">
                          {data?.bank_info?.bank_account}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Flex>
            <Box>
              <Box py="2" display={pdfUrls.length === 0 ? 'none' : 'block'}>
                <Button
                  isDisabled={pdfUrls.length === 0}
                  onClick={() => openMultipleLinks(pdfUrls)}
                >
                  Download PDF
                </Button>
              </Box>
              {imageUrls.map((url: string, idx: number) => {
                return (
                  <ActivitiesImagePayment
                    key={`payment-${idx}`}
                    url={url}
                    alt={`Bukti Pembayaran-${idx}`}
                    objectFit="cover"
                    loading="lazy"
                    pb={2}
                  />
                );
              })}
            </Box>
          </>
        ) : null}
        <Divider />
      </FormProvider>
    </AdminLayout>
  );
}

export default ActivitiesDetailPOPage;
