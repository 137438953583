import {
  HStack,
  Tab,
  TabIndicator,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import {
  BackOfficeOrderChangelogsData,
  BackofficeStatusHistoryData,
} from '@diamond/shared/types';
import { DataTable, Modal } from '@diamond/shared/ui';
import {
  adminCurrencyFormatter,
  adminDateFormatter,
  catalogMasking,
  formatOrderStatus,
} from '@diamond/shared/utils';
import { formatDeliveryDate } from '@diamond/sol-admin/common';
import {
  useActivitiesDetail,
  useOrderChangelogs,
  useOrderHistory,
} from '@diamond/sol-admin-context';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<BackofficeStatusHistoryData>();
const columnHelperChangelogs =
  createColumnHelper<BackOfficeOrderChangelogsData>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tableColumnHistory: any = [
  columnHelper.accessor('status', {
    cell: (info) => formatOrderStatus(info.getValue()),
    header: 'Status Order',
  }),
  columnHelper.accessor('created_at', {
    cell: (info) => adminDateFormatter(info.getValue()),
    header: 'Tanggal',
  }),
  columnHelper.accessor('client_device', {
    cell: (info) => {
      const { origin } = info.row.original;
      if (origin === 'so_automation') return '';
      return info.getValue();
    },
    header: 'Device',
  }),
  columnHelper.accessor('client_version', {
    cell: (info) => {
      const { origin } = info.row.original;
      if (origin === 'so_automation') return '';
      return info.getValue();
    },
    header: 'OS Version',
  }),
  columnHelper.accessor('client_type', {
    cell: (info) => {
      const { origin } = info.row.original;
      if (origin === 'so_automation') return '';
      return info.getValue();
    },
    header: 'Client Type',
  }),
  columnHelper.accessor('created_by.email', {
    cell: (info) => {
      const { origin } = info.row.original;
      if (origin === 'so_automation') return 'Sistem';
      return info.getValue();
    },
    header: 'Email',
  }),
  columnHelper.accessor('created_by.name', {
    cell: (info) => {
      const { origin } = info.row.original;
      if (origin === 'so_automation') return '';
      return info.getValue();
    },
    header: 'Nama User',
  }),
  columnHelper.accessor('note', {
    cell: (info) => info.getValue(),
    header: 'Catatan',
  }),
];

const CHANGED_FIELD_DISPLAY_NAMES: Record<
  BackOfficeOrderChangelogsData['changed_field'],
  string
> = {
  confirmed_quantity: 'Kuantitas',
  latest_price: 'Harga',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tableColumnChangelogs: any = [
  columnHelperChangelogs.accessor('changed_field', {
    cell: (info) => CHANGED_FIELD_DISPLAY_NAMES[info.getValue()],
    header: 'Data',
  }),
  columnHelperChangelogs.accessor('description', {
    cell: (info) => info.getValue(),
    header: 'Dekrsipsi',
  }),
  columnHelperChangelogs.accessor('origin', {
    cell: (info) => {
      switch (info.getValue()) {
        case 'admin':
          return info.row.original.created_by;
        case 'sap':
          return 'SAP';
        default:
          return '-';
      }
    },
    header: 'User',
  }),
  columnHelperChangelogs.accessor('created_at', {
    cell: (info) => adminDateFormatter(info.getValue()),
    header: 'Waktu',
  }),
];

type OrderHistoryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedDataId: string;
};

export function OrderHistoryModal({
  isOpen,
  onClose,
  selectedDataId,
}: OrderHistoryModalProps) {
  const { data: dataOrderChangelogs, isPending: isLoadingChangelogs } =
    useOrderChangelogs(selectedDataId);
  const { data: dataActivityHistory, isPending: isLoadingHistory } =
    useOrderHistory(selectedDataId);

  const { activitiesDetailData } = useActivitiesDetail(selectedDataId, {
    order_by: '',
    direction: '',
    size: 15,
    page: 1,
  });

  const selectedData = activitiesDetailData?.order;

  if (!selectedData) return null;

  return (
    <Modal
      name="status_history"
      title="Riwayat Pesanan"
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
      labelCancel="Tutup"
      hideSubmit
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <HStack
        direction="column"
        verticalAlign="top"
        mb={4}
        alignItems="baseline"
      >
        <TableContainer>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>Nomor PO SOL</Td>
                <Td fontWeight="bold">{selectedData?.transaction_id || '-'}</Td>
              </Tr>
              <Tr>
                <Td>Nomor Sub PO SOL</Td>
                <Td fontWeight="bold">{selectedData?.sub_po_number || '-'}</Td>
              </Tr>
              <Tr>
                <Td>Tanggal PO</Td>
                <Td fontWeight="bold">
                  {adminDateFormatter(selectedData?.created_at) || '-'}
                </Td>
              </Tr>
              <Tr>
                <Td>Nomor SO</Td>
                <Td fontWeight="bold">{selectedData?.so_number || '-'}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>Nomor Ship To</Td>
                <Td fontWeight="bold">{selectedData?.ship_to['code']}</Td>
              </Tr>
              <Tr>
                <Td>Nama Shipto</Td>
                <Td fontWeight="bold">{selectedData?.ship_to['name']}</Td>
              </Tr>
              <Tr>
                <Td>Nama Pemesan</Td>
                <Td fontWeight="bold">{selectedData?.ship_to_contact?.name}</Td>
              </Tr>
              <Tr>
                <Td>No HP Pemesan</Td>
                <Td fontWeight="bold">{selectedData?.ship_to_contact?.telp}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>Tanggal Pengiriman</Td>
                <Td fontWeight="bold">
                  {formatDeliveryDate(
                    selectedData?.delivery_option,
                    selectedData?.delivery_date
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Katalog</Td>
                <Td fontWeight="bold">
                  {catalogMasking(
                    selectedData?.division || '',
                    String(selectedData?.ship_to?.['distribution_channel'])
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Distribution Channel</Td>
                <Td fontWeight="bold">
                  {selectedData?.ship_to['distribution_channel']}
                </Td>
              </Tr>
              <Tr>
                <Td>Kota</Td>
                <Td fontWeight="bold">{selectedData?.ship_to['city']}</Td>
              </Tr>
              <Tr>
                <Td>Total Order Per Divisi</Td>
                <Td fontWeight="bold">
                  {adminCurrencyFormatter(selectedData?.subtotal)}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </HStack>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Tab>Riwayat Pesanan</Tab>
          <Tab>Perubahan Data</Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="2px" bg="blue" borderRadius="1px" />
        <TabPanels>
          <TabPanel>
            <DataTable
              data={dataActivityHistory ? dataActivityHistory : []}
              columns={tableColumnHistory}
              isLoading={isLoadingHistory}
            />
          </TabPanel>
          <TabPanel>
            <DataTable
              data={dataOrderChangelogs ? dataOrderChangelogs : []}
              columns={tableColumnChangelogs}
              isLoading={isLoadingChangelogs}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Modal>
  );
}
