import { Image as ChakraImage, Image, VStack } from '@chakra-ui/react';
import { STOCK_UI } from '@diamond/shared/decimal-util-web';
import { Text } from '@diamond/shared/ui';
import { adminCurrencyFormatter } from '@diamond/shared/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { dinero, toDecimal } from 'dinero.js';

export type ColumnsType = {
  item_code: number;
  images: string[];
  quantity?: number;
  subtotal: number;
  title: string;
  price?: number;
  is_new_product: boolean;
};

const columnHelper = createColumnHelper<ColumnsType>();

export const columns = [
  columnHelper.accessor('item_code', {
    cell: (info) => {
      const isNewProduct = info.row.original.is_new_product;
      return (
        <VStack alignItems="flex-start">
          {isNewProduct ? (
            <span
              style={{
                display: 'inline-block',
                backgroundColor: 'red',
                padding: '0.25rem 0.5rem',
                fontSize: '75%',
                fontWeight: 600,
                lineHeight: 1,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '0.25rem',
                color: 'white',
              }}
            >
              New
            </span>
          ) : null}
          <span>{info.getValue()}</span>
        </VStack>
      );
    },
    header: 'Kode Item',
  }),
  columnHelper.accessor('images', {
    cell: (info) => {
      const imageData = info.row.original.images;
      const image = imageData
        ? imageData[imageData.length - 1]
        : 'assets/images/product_placeholder.png';
      return (
        <Image
          as={ChakraImage}
          src={image}
          alt={''}
          objectFit="cover"
          borderRadius="md"
          border="1px"
          borderColor="gray.33"
          w="10"
          h="10"
        />
      );
    },
    header: 'Gambar',
  }),
  columnHelper.accessor('title', {
    cell: (info) => info.getValue(),
    header: 'Nama Produk',
  }),
  columnHelper.accessor('quantity', {
    cell: (info) => {
      const jumlahCheckout = Number(
        toDecimal(
          dinero({
            amount: Number(info.getValue()),
            currency: STOCK_UI,
          })
        )
      );

      return (
        <VStack alignItems="flex-start">
          <Text>{jumlahCheckout}</Text>
        </VStack>
      );
    },
    header: 'Jumlah Checkout',
  }),
  columnHelper.accessor('price', {
    cell: (info) => adminCurrencyFormatter(info.getValue()),
    header: 'Harga',
  }),
  columnHelper.accessor('subtotal', {
    cell: (info) => adminCurrencyFormatter(info.getValue()),
    header: 'Total Harga',
  }),
];
