import { format, fromUnixTime, subDays } from 'date-fns';
import { id } from 'date-fns/locale';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';

/**
 * Add a specified number of seconds or minutes to the current time.
 *
 * Example:
 *  - const date = new Date('2022-12-06T12:00:00-05:00');
 *  - const updatedDate1 = addTime(date, 10); // adds 10 seconds (default units)
 *  - const updatedDate2 = addTime(date, 5, 'minutes'); // adds 5 minutes
 *
 * @param date
 * @param time
 * @param units
 * @returns number
 */
export function addTime(date: Date, time: number, units = 'seconds') {
  if (units === 'seconds') {
    date.setSeconds(date.getSeconds() + time);
  } else if (units === 'minutes') {
    date.setMinutes(date.getMinutes() + time);
  }
  return date;
}

/**
 * Convert issuet at (iat) timestamp timestamp to a date object.
 *
 * @param iat
 * @returns date
 */
export function convertJwtIatToIsoDate(iat: number, addSeconds = 0): Date {
  const isoDate = new Date(iat * 1000);
  if (addSeconds > 0) {
    isoDate.setSeconds(isoDate.getSeconds() + addSeconds);
    return isoDate;
  }
  return new Date(iat * 1000);
}

export function adminDateFormatter(value: number | undefined | null) {
  if (value) {
    const date = new Date(value);
    // const date = new Date(value * 1000);
    // return moment(value).format('DD/MM/yyyy h:mm a').toUpperCase();
    return format(date, 'dd/MM/yyyy h:mm a').toUpperCase();
  } else {
    return '';
  }
}

export function dateSwapper(date?: string) {
  if (date) {
    const [dd, mm, yyyy] = date.split('/');
    return [mm, dd, yyyy].join('/');
  } else {
    return '1/1/2001';
  }
}

export function dateToUnix(date?: string, time?: string, isSwapDate?: boolean) {
  if (isSwapDate === true) {
    const gmtDate = new Date(`${dateSwapper(date)} ${time}`);
    return moment(gmtDate).utc(true).unix();
  } else {
    return moment(new Date(`${date} ${time}`))
      .utc(true)
      .unix();
  }
}

export function unixToHumanDate(
  timestamp: number,
  formatStr?: string,
  timezone?: string
): string {
  const dateFormat = formatStr ? formatStr : 'dd MMMM yyyy';
  const tzone = timezone ? timezone : 'UTC';
  const date = fromUnixTime(timestamp);
  return formatInTimeZone(date, tzone, dateFormat, { locale: id });
}

export function getLastDaysDate(n: number, utc = false): Date {
  const date = subDays(new Date(), n);
  return utc ? zonedTimeToUtc(date, 'Asia/Jakarta') : new Date(date);
}

export function unixTimeFromDaysAgo(n: number): number {
  const now = new Date();
  return Number((now.setDate(new Date().getDate() - n) / 1000).toFixed(0));
}
