import { AuthLayout } from '@diamond/sol-admin/authentication';
import * as Sentry from '@sentry/react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { AccountPage } from '../pages/account';
import AccountDetail from '../pages/account/[detail]';
import { ActivitiesPage } from '../pages/activities/Activities';
import { ActivitiesPaymentPage } from '../pages/activities/activities-payment';
import { ActivitiesDetailPage } from '../pages/activities/ActivitiesDetail';
import { ActivitiesDetailPOPage } from '../pages/activities/ActivitiesDetailPO';
import {
  CreatePasswordPage,
  LoginPage,
  ResetPasswordPage,
} from '../pages/auth';
import { BannerPage } from '../pages/banners/Banners';
import { ChangePassword } from '../pages/change-password';
import ErrorPage from '../pages/ErrorPage';
import { HighlightPage } from '../pages/highlight';
import { HomePage } from '../pages/Home';
import { KeywordsPage } from '../pages/keywords';
import { MinimumOrderPage } from '../pages/minimum-order';
import { User } from '../pages/user';
import { ProtectedRoute } from './ProtectedRoute';
import { withLoggedOut } from './withAuthenticated';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function AppRoutes() {
  const navigate = useNavigate();
  const AccessDeniedPage = withLoggedOut(LoginPage);

  return (
    <SentryRoutes>
      {/* Public routes */}
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<AccessDeniedPage />} />
        <Route
          path="/create-password/:token"
          element={<CreatePasswordPage />}
        />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Route>

      {/* Protected routes */}
      <Route
        element={
          <ProtectedRoute
            requiredRoles={['super_admin', 'cs_katalog', 'cs_cabang']}
          />
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/activities" element={<ActivitiesPage />} />
        <Route
          path="/activities/:activitiesId"
          element={<ActivitiesDetailPage />}
        />
        <Route
          path="/activities/:activitiesId/payment-proof"
          element={<ActivitiesPaymentPage />}
        />
        <Route
          path="/activities/meta/:orderMetaId"
          element={<ActivitiesDetailPOPage />}
        />
        <Route path="/change-password" element={<ChangePassword />} />
      </Route>

      <Route element={<ProtectedRoute requiredRoles={['super_admin']} />}>
        <Route path="/account" element={<AccountPage />} />
        <Route path="/account/:userId/:shiptoId" element={<AccountDetail />} />
        <Route path="/highlight" element={<HighlightPage />} />
        <Route path="/banners" element={<BannerPage />} />
        <Route path="/keywords" element={<KeywordsPage />} />
        <Route path="/minimum-order" element={<MinimumOrderPage />} />
        <Route path="/user" element={<User />} />
      </Route>

      {/* 👇️ only match this when no other routes match */}
      <Route
        path="*"
        element={
          <ErrorPage
            message="Terjadi Kesalahan"
            subtitle="Halaman tidak ditemukan"
            buttonTitle="Kembali Ke Beranda"
            action={() => navigate('/')}
          />
        }
      />
      <Route
        path="/activities/error"
        element={
          <ErrorPage
            message="Terjadi Kesalahan"
            subtitle="Maaf terdapat harga atau stock barang yang tidak ditemukan. Mohon periksa kembali data stock dan harga pada SAP"
            buttonTitle="Kembali Ke Laman Activities"
            action={() => navigate('/activities')}
          />
        }
      />
    </SentryRoutes>
  );
}
